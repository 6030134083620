.navbar {
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.navbar1 {
  padding: 16px;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  line-height: 2rem;
}

@media (max-width: 600px) {
  .navbar1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .navbar {
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Main_cart_div {
    margin-left: 0 !important;
  }
}

.navbar-item {
  color: black;
  padding: 8px 15px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  font-size: 20px;
  cursor: pointer;
}

.navbar-item.active {
  border-bottom: 3px solid black;
}

.empty_cart {
  font-size: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.navba.r-item.active {
  border-width: 5px;
  border-bottom-color: black;
  /* Set the bottom border color to black for active page */
}

.Main_cart_div {
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
}

.Section3_underline {
  width: 65px;
  height: 2px;
  background-color: #000000;
  margin-bottom: 1rem;
  margin-top: 2rem;
  display: flex;
}

.Cart_title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #141420;
}

.cart {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-left: 2rem;
}

.trash{
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: end;
  align-items: center;
}

.trash svg{
  width: 2rem;
  height: 2rem;
  fill: red;
  cursor: pointer;
}

.table_cart {
  width: 70%;
  margin-bottom: 2rem;
}

.Product_div {
  width: 35%;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #141420;
}

.products_div_cart {
  display: flex;
  align-items: center;
}

.cart_img {
  width: 70px;
  height: 70px;
  transition: transform 0.3s ease-in-out;
  /* Add a smooth transition for the transform property */
}

.cart_img:hover {
  z-index: 2;
  transform: scale(1.2);
  /* Scale the image by 10% on hover */
}

.cart_description_div {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.navbar-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.price_div {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #141420;
}

.Price_td {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;

  color: #141420;
}

.quantity_div {
  padding: 0.7rem;
  font-weight: 500;
  text-align: center;
  color: #141420;
  border: #d9d9d9 solid 1px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity_div_remove {
  font-weight: 500;
  text-align: center;
  color: #141420;
  border: #d9d9d9 solid 1px;
  cursor: pointer;
  padding: 0.4rem 0.7rem;
  font-size: 1.4rem;
}

.quantity_div_Container {
  display: flex;
  justify-content: center;
}

.remove_button {
  font-weight: 500;
  font-size: 13px;
  line-height: 23px;
  color: #141420;
  border: #d9d9d9 solid 1px;
  cursor: pointer;
}

.remove_button:hover {
  color: red;
}

.Section4_underline {
  width: 95%;
  height: 1px;
  background-color: #e2e2e2;
  margin-bottom: 1rem;
  margin-top: 2rem;
  display: flex;
}

.cart_products {
  margin-top: 4rem;
  width: 65%;
}

.cart_titles {
  display: flex;
  /* justify-content: center; */
  margin-top: 2rem;
  margin-bottom: 2rem;

  width: 65%;
  margin-right: 3rem;
  text-align: start;
  gap: 2rem;
}

.cart_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  /* identical to box height, or 177% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #000000;
}

.width1 {
  width: 35%;
}

.width2 {
  width: 15%;
}

.width3 {
  width: 20%;
}

.cart_product {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: left;
  flex-wrap: wrap;
}

.cart_product_description {
  max-width: 500px;
  color: #000000;
  text-align: initial;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
}

.cart_price {
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #141420;
  /* text-align: initial; */
  width: 100px;
  margin-top: 8px;
}

.all_container {
  display: flex;
  flex-wrap: wrap;
}

.price-container{
  display: flex;
}

.cart_price_text,
.cart_subtotal_text {
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: #141420;
  text-align: left;
}
.small-text{
  font-size: 0.9rem !important;
  white-space: nowrap;
}

.cart_quantity {
  width: 220px;
  margin-top: 8px;
}

.cart_subtotal {
  width: 175px;
}

/*
.cart_total {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 25px;
    /* position: absolute;
    width: 350px;
    height: 520px;
    /* left: 950px;
    top: 449px;
    background: #F7F7F7;
    box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.25);
    border-radius: 1px;
    margin-bottom: 5rem;

}*/

.cart_total1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 25px;
  width: 350px;
  height: 520px;
  top: 3rem;
  background: #f7f7f7;
  border-radius: 1px;
  margin-bottom: 5rem;
}

/*nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn*/
.cart_total {
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 25px;
  width: 290px;
  background: #f7f7f7;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.25);
  border-radius: 1px;
}

.cart_products_allcont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  /* width: max-content;
  margin-left: 0rem; */
}

.cart_total_text {
  letter-spacing: 0.1em;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: #333333;
}

.cart_total_textch {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  font-style: normal;
  color: #333333;
}

.cart_subtotals_text {
  margin-top: 1rem;
}

.cart_subtotals_text1 {
  letter-spacing: 0.1em;
  font-weight: bolder;
  font-size: 13px;
  line-height: 25px;
  text-transform: uppercase;
  color: #141420;
  margin-bottom: 5px;
  text-align: initial;
}

.cart_subtotals_text2 {
  letter-spacing: 0.1em;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 5px;
  text-align: initial;
}

.cart_underline {
  width: 303px;
  height: 1px;

  background: rgba(51, 51, 51, 0.12);
}

.cart_subtotals_text3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: initial;
}

.cart_subtotals_text4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  text-align: initial;
  margin-top: 5px;
}

.cart_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 39px;
  gap: 10px;
  color: white;
  width: 303px;
  height: 52px;
  background: #141420;
  box-shadow: 0px 2px 0px #000000;
  border-radius: 1px;
  cursor: pointer;
}

.cart_coupon_cont {
  display: flex;
  margin-top: 4rem;
}

.cart_coupon_input {
  width: 200px;
  height: 40px;

  background: #fdfdfd;
  border: 1px solid rgba(51, 51, 51, 0.12);
}

.cart_coupon_text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 39px;
  gap: 10px;
  color: white;
  width: 150px;
  height: 10px;
  background: #141420;
  box-shadow: 0px 2px 0px #000000;
  border-radius: 1px;
  cursor: pointer;
  margin-left: 1rem;
}

/* .filter_container_text{
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    width: 798px;
height: 120px;

left: 10px;
top: 287px;

background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
} */
.my_cart {
  height: 13rem;
  margin-right: 9rem;
  margin-bottom: 1rem;
  width: 100%;
  background: #222235;
}

.inside {
  height: 13rem;
  margin-right: 9rem;
  margin-bottom: 1rem;
  width: 100%;
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  justify-content: flex-start;
}

.homecontainer {
  width: 20rem;
  color: #f7f7f7;
  flex-direction: column;
  display: flex;
  column-gap: 3rem;
}

.home {
  padding-top: 4rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff53;

  margin-left: 5.3rem;
  margin-bottom: 0.5rem;
}

.myCart {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 54px;
  /* identical to box height */

  text-transform: uppercase;
  margin-left: 3rem;
  color: #ffffff;
  padding-bottom: 4rem;
}

.cartname {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
}

.checkbox-label {
  display: flex;
  align-items: left;
  justify-content: left;
  margin-right: 10px;
  font-weight: 300;
  padding: 9px 35px;
  cursor: pointer;
  color: white;
  /* Default text color */
}

/* Styles for the radio button circle */
.checkbox-label input[type="radio"]::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #000;
  /* background-color: #fff; */
}

/* Styles for the radio button circle when checked */
.checkbox-label input[type="radio"]:checked::before {
  border-color: black;
  /* Border color when checked */
  background-color: black;
  /* Background color when checked */
}

.watching_info_displays {
  display: flex;
  margin-right: 3rem;
}

.radio-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.bill {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.billing-property {
  white-space: pre-line;
  width: max-content;
  border: 1px solid;
  width: 50vw;
  padding: 10px 10px;
  border-radius: 10px;
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inputradio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: inline-block;
}

.empty_cart_message {
  font-size: 45px;
  margin-bottom: 5rem;
  margin-top: 5rem;
  display: flex;
  width: fit-content;
}

.centered_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 26rem;
}

.empty_cart_text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 39px;
  gap: 10px;
  color: white;
  width: fit-content;
  height: 10px;
  background: #141420;
  box-shadow: 0px 2px 0px #000000;
  border-radius: 1px;
  cursor: pointer;
  margin-bottom: 5rem;
}

.cart-info {
  width: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bill_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 39px;
  background: #000000;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  padding: 10px;
  border-radius: 9px;
  margin-left: 2rem;
}

.ship-h1 {
  text-align: left;
  /* Set text alignment to the left */
  margin-left: 20px;
  /* Add some left margin for better readability */
}

.cart_button_bill {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #000000;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-top: 20px;
  margin-left: 40%;
  margin-bottom: 20px;
}

.detailed {
  display: flex;
  justify-content: right;
  align-content: right;
  white-space: nowrap;
}

.name {
  align-items: center;
  display: flex;
  font-weight: 500;
  width: 8rem;
}

.also_bought {
  margin-top: 10rem;
  margin-bottom: 10rem;
  justify-content: center;
  align-items: center;
}

.billingandshipping {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1050px) {
  .cart_products_allcont {
    display: flex;
    flex-direction: column;
  }

  .cart_products {
    width: 100%;
  }

  .cart_titles {
    width: 100%;
  }

  .cart_total {
    margin-top: 0rem;
    position: relative;
    top: auto;
    right: auto;
  }
}

@media screen and (max-width: 750px) {
  .cart_product {
    justify-content: center;
  }

  .cart_products_allcont {
    align-items: flex-start;
    justify-content: center;
  }

  .all_container {
    justify-content: center;
    align-items: center;
  }

  .navbar-item {
    width: 10rem;
    font-size: 10px;
  }


  .cart_product_description {
    width: 90%
  }
}

@media screen and (max-width: 660px) {
  .cart_product_description {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .myCart {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .billing-property {
    justify-content: center;
    align-items: center;
  }

  .bill {
    justify-content: center;
    align-items: center;
  }

  .billing-property {
    margin-left: 0rem;
  }

  .name {
    justify-content: flex-start;
  }

  .bill_btn {
    margin-left: 0rem;
  }
}

.edit_content {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.edit_labels {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 13.3rem;
}

.edit_input-name {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 5px;
  width: 20rem;
}

.edit_input_mobile {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 5px;
  width: 12rem;
}

.edit_input {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 5px;
}

.name-bill-ship {
  align-items: center;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
}

.country {
  gap: 1rem;
  align-items: center;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  flex-wrap: wrap;
}

.address {
  gap: 1rem;
  align-items: center;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  flex-wrap: wrap;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  /* Optional: Add some spacing between input groups */
}

.bill_ship_btn {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 39px;
  background: #000000;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  padding: 10px;
  border-radius: 9px;
  margin-left: 2rem;
  width: fit-content;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.city {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 4rem;
  padding-left: 15px;
  margin-bottom: 5px;
}

.react-multi-carousel-item {
  justify-content: center;
  margin-bottom: 3rem;
}

/* .carousel{
  margin-bottom: 5rem;
} */