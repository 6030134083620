.Main_header_section {
  height: 110px;
  display: flex;
  flex-direction: row;
  transition: all 0.5s;
  /* box-shadow: 0px 14px 16px -10px rgba(0, 0, 0, 0.15) !important; */
  z-index: 1000000;
  /* padding: 0 20px 0 30px; */
  /* position: fixed !important; */
  background-color: #f4f4f4;
  width: 100%;
  top: 0;
}


a {
  text-decoration: none;
  color: #000000;
}

.favorite-count1 {
  position: absolute;
  top: 64%;
  left: 50;
  transform: translate(-50%, -50%);
  background-color: red;
  color: #ffffff;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 11px;
  width: 1px;
  justify-content: center;
  display: flex;
  height: 9px;
  color: white;
  align-items: center;
  /* border: 0px solid black; */
  margin-left: 1.6rem;
  margin-top: -8px;
}

.Saleandpre {
  font-family: sans-serif;
  font-weight: 200;
  border-right: " 1px solid white" !important;
  cursor: pointer;
}

.Saleandpre1 {
  font-family: sans-serif;
  font-weight: 200;
  cursor: pointer;
}

.Main_header_section_in {
  height: 110px;
  display: flex;
  flex-direction: row;
  transition: all 0.5s;
  /* box-shadow: 0px 14px 16px -10px rgba(0, 0, 0, 0.15) !important; */
  z-index: 1000000;
  /* padding: 0 20px 0 30px; */
  position: absolute !important;
  background-color: rgba(0, 0, 0, 0.15);
  color: white;
  width: 100%;
  top: 0;
}

.scroll header {
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
}

.header_logo {
  width: 14rem;
}

.header_logo1 {
  width: 10rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.header_icon {
  cursor: pointer;
  color: aliceblue;
}

.header_icons_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* margin-left: 2rem; */
  width: 30%;
  justify-content: center;
}

.header_icons_container svg path {
  fill: black;

}

.header_hamburger_menu_cont {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header_icons_container_right {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 4rem;
  width: 30%;
}

.header_icons_container_right1 {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  margin-right: 4rem;
  width: 30%;
  justify-content: center;
}

.header_icons_container_right svg {
  path {
    fill: black;
  }
}

.header_wp_cont {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
}

.wp_a {
  text-decoration: none;
  color: #000000;
}

.header_wp_text {
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
}

.divider {
  height: 30px;
  left: 10.06%;
  right: 88.41%;
  top: 39.81%;
  bottom: 60.19%;
  border: 1px solid #000000;
  transform: rotate(180deg);
}

.divider-white {
  border: 1px solid white !important;

}

.header_logo_container {
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
  width: 30%;
  justify-content: center;
}

.search-input {
  background-color: transparent;
  border-color: transparent;
  border-width: 1px;
  height: 1.5rem;
  flex: 1;
  /* This allows the input to expand and fill the remaining space */
  margin-right: 10px;
  color: aliceblue;
}

.search-input2 {
  background-color: transparent;
  border-color: transparent;
  border-width: 1px;
  height: 1.5rem;
  flex: 1;
  /* This allows the input to expand and fill the remaining space */
  margin-right: 10px;
  color: black;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: transparent;
  /* Background color of the search bar */
  border: 1px solid #ccc;
  /* Border around the search bar */
  border-radius: 5px;
  /* Rounded corners */
  /* padding: 5px; */
  /* Padding inside the search bar */
  width: 260px;
}

.search-bar input {
  outline: none;
}

.search-container {
  display: flex;
  /* This makes the children inline */
  align-items: center;
  /* Vertically center the content */
  margin-left: -1rem;
}

.search-bar-dark-theme .header_icon svg {
  stroke: #000000;
}

.search-bar-dark-theme input::placeholder {
  color: #000000;
}

.search-bar-dark-theme input {
  outline: none;
  color: #000000;
}

.input-container {
  border: 0px solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 2.2rem;
  width: 100%;
  color: white;
  margin-left: 1rem;
  padding-right: 1rem;
}

.input-container::placeholder {
  color: white;
  font-weight: 300;
}

.search-input2::placeholder {
  color: black;
  font-weight: 300;
}

@media screen and (max-width: 2000px) {
  .header_hamburger_menu_cont {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .header_hamburger_menu_cont {
    display: flex;
    width: 30%;
    justify-content: center;
  }

  .header_icons_container_right1 {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .header_wp_text {
    display: none;
  }

  .header_icons_container_right {
    display: none;
  }

  .header_icons_container {
    display: none;
  }

  .header_hamburger_menu_cont {
    display: flex;
    margin-right: 2rem;
    width: 30%;
    justify-content: center;
  }

  .divider {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .header_icons_container {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .header_logo_container {
    width: 75%;
  }
}

@media screen and (max-width: 380px) {
  .header_logo {
    width: 240px;
  }
}

.search-icon {
  color: black;
}