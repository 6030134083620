.reset_password_form {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: initial;
  margin-top: 4rem;
  height: auto;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.email_input_reset_pass {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  width: 50%;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 1.5rem;
  margin-top: 0.7rem;
  margin-left: 0.5rem;
}

.btn_reset_pass {
  width: 35%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 39px;
  background: #000000;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-top: 20px;
  width: max-content
}

.error_msg {
  font-size: 0.8rem;
  color: #f06a80;
  margin-bottom: 1rem;
}

.input_label_change_pass {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
  padding-top: 15px;
}