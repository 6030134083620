.Main_Section2_div {
    margin-top: 4rem;
}

.Section2_Header1 {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 5px;
}

.Section2_Header2 {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.Section2_Headeer_underline {
    width: 100px;
    height: 2px;
    background-color: #000000;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.Section2_buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    margin-top: 4rem;
}

.Section2_button {
    background-color: transparent;
    /* border: 2px solid #000000; */
    cursor: pointer;
    padding: 15px;
    font-size: 1rem;
    font-weight: 600;
    width: 200px;
    letter-spacing: 2px;
    border: none;
}

.Section2_button2 {
    margin-top: 1rem;
}

.Section2_Cards {
    width: 80%;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

}

.Section2_button_active {
    background-color: transparent;
    border: 2px solid #000000;
    cursor: pointer;
    padding: 15px;
    font-size: 1rem;
    font-weight: 600;
    width: 200px;
    letter-spacing: 2px;
}

.Section2_button_view {
    background-color: transparent;
    border: 2px solid #000000;
    cursor: pointer;
    padding: 10px;
    font-size: 1rem;
    font-weight: 600;
    width: 200px;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
    .Section2_Header2 {
        font-size: 10vw;
    }
}