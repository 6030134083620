.Main_Section1_div {
  background: #f4f4f4;
  height: 700px;
}

.Main_Section1_Container {
  width: 100%;
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

/* @media screen and (max-width: 767px) {
  .Main_Section1_Container {
    flex-direction: column;
  }

} */

.Main_Section2_Container {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.image-container {
  width: 100%;
}

.image_sec {
  width: 600px;
  height: 300px;
}

.image_sec2 {
  width: 400px;
  height: 300px;
}

.Main_Section1_Container {
  margin-top: -6rem;
}

.div1_cont {
  position: relative;
  z-index: 2;
  width: fit-content;
}

.div2_cont {
  position: relative;
  z-index: 2;
  width: fit-content;
}

.text-container {
  position: absolute;
  color: white;
  background: linear-gradient(40deg,
      rgba(0, 0, 0, 0.7) 21.21%,
      rgba(0, 0, 0, 0) 81.85%);
  width: 100%;
  height: 300px;
  top: 0%;
}

.text-container1 {
  position: absolute;
  top: 30%;
  left: 10%;
}

.text-container2 {
  position: absolute;
  top: 40%;
  left: 10%;
  font-size: 2rem;
  width: 70%;
  text-align: initial;
}

.text-container3 {
  position: absolute;
  top: 75%;
  cursor: pointer;
}

.buttonofcont3 {
  background-color: transparent;
  border: 2px solid white;
  cursor: pointer;
  padding: 10px;
  color: white;
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: bold;
  width: 200px;
  letter-spacing: 2px;
}

@media Screen and (max-width: 1050px) {
  .Main_Section1_div {
    height: auto;
  }

  .Main_Section1_Container {
    margin-top: 0rem;
  }
}

@media Screen and (max-width: 630px) {
  .image_sec {
    width: 100%;
  }
}

@media Screen and (max-width: 430px) {
  .image_sec2 {
    width: 100%;
  }

  .text-container2 {
    text-align: center;
    top: 35%;
  }

  .text-container1 {
    left: 30%;
    top: 25%;
  }

  .text-container3 {
    left: 25%;
  }

}

@media Screen and (max-width: 430px) {
  .text-container3 {
    left: 20%;
  }
}

@media Screen and (max-width: 340px) {
  .text-container3 {
    left: 17%;
    top: 82%;
  }

  .text-container1 {
    left: 22%;
  }
}