.billingandshipping {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0rem 0 2rem;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5rem;
}

.bill-ship-cont {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-left: 0rem;
}

.ship-h1 {
  text-align: left;
  /* Set text alignment to the left */
  margin-left: 20px;
  /* Add some left margin for better readability */
}

.cart_total {
  margin-right: 3rem;
}

@media screen and (max-width: 500px) {


  .bill {
    justify-content: center;
    align-items: start;
  }



  .name {
    justify-content: flex-start;
  }

  .bill_btn {
    margin-left: 0rem;
  }

}

.billingandshipping {
  padding: 0;
  margin-left: 2rem;
}

.cart_total {
  margin-right: 3rem;
}

.bill_btn {
  margin-left: 1rem;
}



.edit_content {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.edit_labels {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 13.3rem
}

.edit_input-name {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 5px;
  width: 20rem
}

.edit_input_mobile {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 5px;
  width: 12rem;
}

.edit_input {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 45px;
  padding-left: 15px;
  margin-bottom: 5px;

}



.name-bill-ship {
  align-items: center;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
}

.country {
  gap: 1rem;
  align-items: center;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  flex-wrap: wrap;

}


.address {
  gap: 1rem;
  align-items: center;
  display: flex;
  font-weight: 500;
  flex-direction: row;
  flex-wrap: wrap;

}


.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  /* Optional: Add some spacing between input groups */
}

.bill_ship_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 39px;
  background: #000000;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  padding: 10px;
  border-radius: 9px;
  margin-left: 0rem;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 2rem 1rem;
}

.city {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  height: 4rem;
  padding-left: 15px;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .bill-ship-cont {
    margin-left: 1rem !important;
  }

  .bill-ship-cont {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .cart_total {
    margin: 0rem 0rem 0rem 2rem;
  }

  .name-bill-ship {
    width: 100%;
    gap: 0;
  }

  .country,
  .address {
    gap: 0;
  }

  .edit_input-name,
  .input-group {
    width: 100% !important;
  }

  .input-group select {
    width: 100% !important;
  }

  .edit_input_mobile {
    width: 100%;
  }

  .bill,
  .ship {
    gap: 0;
  }

  .billing-property {
    justify-content: space-between;
    align-items: start;

    margin-left: 0rem;
    width: 100%;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }

  .billingandshipping {
    margin-left: 0rem;
  }
}