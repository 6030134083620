.featured_items_main_container {
  width: 100%;
}

.featured_items_container_1 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.featured_items_container_info1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.featured_items_container_info {
  display: flex;
  flex-direction: column;

  margin: 40px;
  width: 70%;
}

.featured_items_container_img {
  width: 50%;
  padding-top: 3rem;
}

/* .featured_items_container_img img {
  width: 100%;
  height: fit-content;
} */

.Featured_Item {
  height: 450px;
}

@media screen and (max-width: 600px) {
  .Featured_Item {
    height: 20rem;
    /* Adjust the height for smaller screens */
    width: 20rem;
    /* Adjust the width for smaller screens */
  }
}

.featured_item_code {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000000;
  text-align: initial;
}

.featured_item_details_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 51px;
  text-transform: uppercase;
  color: #000000;
  text-align: initial;
  display: flex;
  justify-content: flex-start;
}

.carousel {
  width: 20rem;
  height: 20rem;
}

/* Media query for smaller screens */
/* @media screen and (max-width: 600px) {
  .carousel {
    width: 2rem;
    height: auto;
  }
} */

.silder-conatiner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.silder-conatiner img {
  width: 100%;
  height: 100%;
}

.slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.carcont {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.carousel-containertest {
  width: 40rem;
}

.featured_items_container_4 {
  display: flex;
  justify-content: center;
}

.featured_items_container_2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.featured_items_vid {
  width: 50%;
  height: 400px;
  background-color: #010205;
  padding: 0 4rem;
}

.featured_items_titles {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.featured_items_container_2 .horizontal_divider {
  border: 1px solid #ffffff;
}

.featured_items_main_container .black-divider {
  border: 1px solid black;
}

.items_specifications {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-around;

  margin-bottom: 60px;
}

.featured_items_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.items_specifications_div {
  display: flex;
  flex-direction: column;
}

.items_specifications_label {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13.34px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.items_specifications_content {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13.34px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 340px;
}

.items_specifications_val {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23.34px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.featured_items_main_container .small_divider .horizontal_divider {
  width: 60px;
}

.items_specifications_div {
  max-width: 33.3%;
}

.featured_items_container_3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.featured_items_main_container .react-multi-carousel-item {
  width: auto !important;

}

.featured_items_main_container .react-multi-carousel-list {
  height: 385px;

}

.featured_items_main_container .react-multi-carousel-dot button {
  width: 23px !important;
  height: auto !important;
  margin-right: 0px !important;
  border-radius: unset !important;
}

.featured_items_main_container .react-multi-carousel-dot--active button {
  border-color: #080808 !important;
}

.img_style {
  width: 71%;
}

.section_details {
  margin-top: 50px;
}

.switch_div .featured_items_container_info1 {
  order: 2;
}

.switch_div .featured_items_container_img {
  order: 1;
}

.header-icons {
  cursor: pointer;
}

.main-footer .header-icons {
  color: #ffffff;
}

.professional_watches_sea {
  height: 480px;
  width: 100%;
}

.div_middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 620px;
}

.div_middle .featured_item_details_title {
  text-align: center !important;
}

.div_middle_container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 60px;
}

.div_middle .buttons_div {
  width: 65% !important;
}

.add_to_chart_btn,
.send_inquery_btn {
  cursor: pointer;
}

.watching_info_display {
  margin-right: 3rem;
  gap: 1rem;
}

.threematerial2 {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  gap: 2rem;
  margin-bottom: 1rem;
}

.featured_items_container_img {
  width: 50%;
  display: flex;
  justify-content: center;
}

@media (max-width: 980px) {
  .featured_items_vid {
    width: 90%;
  }

  .featured_items_container_1 {
    display: flex;
    flex-direction: column;
    border-radius: unset;
  }

  .featured_item_details_title {
    justify-content: flex-start;
  }

  .featured_items_container_info1 {
    width: 100%;
  }

  .featured_items_container_img {
    width: 100%;
    padding: 1rem 0 2rem 0;
  }


  .items_specifications {
    width: 94%;
    margin-left: 1rem;
  }

  .featured_items_container_info {
    align-items: center;
  }

  .switch_div .featured_items_container_info1 {
    order: 1;
  }

  .switch_div .featured_items_container_img {
    order: 2;
  }
}

@media (max-width: 500px) {
  .items_specifications {
    flex-direction: column;
  }

  .items_specifications_div {
    max-width: none !important;
  }
}

@media screen and (max-width: 768px) {
  .featured_items_vid {
    padding: 0 1rem;
  }
}