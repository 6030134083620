.Main_stories_div {
  width: 20rem;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .Main_stories_div {
    width: 100%;
  }
}

.Stories_img_container {
  width: 100%;
}

.Stories_img {
  width: 27rem;
  height: 27rem;
}

.Main_cards_container {
  position: relative;
  z-index: 2;
  width: 50%;
}


@media screen and (max-width:765px) {
  .Main_cards_container {
    width: 100%;
  }

}

.Stories_text_container {
  position: absolute;
  color: white;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 7px;

  position: absolute;
  width: 380px;

  height: 100px;
  left: 65px;
  top: 55%;
}

.Stories_text1 {
  font-size: 2rem;
  line-height: 28px;
  text-align: initial;
}

.Stories_text2 {
  font-size: 12px;
  line-height: 28px;
  margin-top: 4px;
}

.Stories_text3 {
  font-size: 13px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .Stories_text_container {
    width: 16rem;

    height: 100px;
  }

  .Stories_img {
    width: 24rem;
    height: 24rem;
  }
}