.rout {
  display: flex;
  flex-direction: row;
  margin-left: 10rem;
  margin-top: 3rem;
}

.homeroute {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.3;
}

.watchroute {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.3;
}

.productroute {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 10rem;
  gap: 2rem;
}

.image {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
}

.imagescoloumn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.smallimage {
  max-width: 4rem;
}

.smallimage :hover {
  border: 2px solid blue;
}

.image-conto {
  width: 25rem;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
}

@media (max-width: 1404px) {
  .description {
    width: 30%;
  }
}

@media (max-width: 1005px) {
  .description {
    width: 100%;
    margin-top: 4rem;
  }
}

.watchname {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: #000000;
  width: 100%;
  text-align: start;
  margin-bottom: 1rem;
}

.brandpic {
  margin-bottom: 2rem;
  height: 4rem;
  width: 16rem;
}

.code {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height, or 145% */
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #000000;
}

.cont1 {
  display: flex;
  align-items: start;
  margin-top: 2rem;
  margin-left: 0rem;
  margin-bottom: 1rem;
}

.partner-item-container {
  display: flex;
  /* Change this to flex to make the partner and item code divs inline */
  margin: 0;
  /* Optional, adjust margin as needed */
  align-items: center;
  /* To vertically align the content if needed */
}

.partner {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.1176px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  margin-right: 1rem;
  font-size: 21px;
}

.item-code {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  /* font-size: 11.1176px; */
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  font-size: 15px;
}

.soldby {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14.12px;
  line-height: 10px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 1rem;
}

.descriptiontext {
  margin-top: 2rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  opacity: 0.8;
}

.addcart {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 113% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: #000000;
  border: 0.847134px solid #000000;

  color: #ffffff;
  margin-right: 1rem;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.heart {
  cursor: pointer;
}

.inquiry1 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 113% */
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;

  color: #000000;

  padding: 0.5rem;
  background-color: #ffffff;
  align-items: center;
}

.inquiry:hover {
  cursor: pointer;
}

.title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-top: 2rem;
  /* Black */

  color: #000000;
  margin-bottom: 1rem;
}

ul {
  list-style-type: none;
  /* Remove bullet points */
  padding: 0;
  /* Remove default padding */
  margin: 0;
  /* Remove default margin */
}

.threematerial1 {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  margin-top: 2rem;
  gap: 2rem;
  align-items: center;
}

.threematerial1 p {
  color: red;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.group {
  display: flex;
  flex-direction: row;
  padding-right: 2rem;
  gap: 2rem;
}

.eachone {
  display: flex;
  flex-direction: column;

  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  /* identical to box height, or 204% */

  letter-spacing: 0.5px;
  color: #757575;
}

.red {
  color: red;
}

.SecondContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7rem;
  margin-bottom: 4rem;
  flex-wrap: wrap;
}

.heart {
  width: 30.9px;
  height: 18.23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart svg {
  width: 100%;
}

.titlecont {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.32px;
  line-height: 14px;
  /* identical to box height, or 78% */

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.titlecont2 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  text-align: center;

  color: #000000;

  opacity: 0.8;
}

.button {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 113% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #000000;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.first {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}

.second {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

@media (max-width: 671px) {
  .container {
    margin-left: 2rem;
  }

  .rout {
    margin-left: 2rem;
  }
}

@media (max-width: 437px) {
  .image-conto {
    width: 17rem;
  }
}

.attribute-label {
  display: flex;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 135% */
  letter-spacing: 0.5px;
  color: #101010;
  padding-bottom: 1rem;
  text-align: start;
  width: 7.5rem;
}


.details {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #101010;
  padding-bottom: 1rem;
  text-align: start;
  font-family: sans-serif;
}

.details-block {
  display: block;
}

.details123123 {
  display: flex;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 135% */
  letter-spacing: 0.5px;
  color: #101010;
  padding-bottom: 1rem;
  text-align: start;
  margin-left: 0.5rem;
}

.price {
  font-size: 40px;
  display: flex;
  font-family: sans-serif;
  font-style: normal;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  justify-content: center;
  align-items: center;
  margin-left: 0rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

li {
  display: flex;
  flex-direction: row;
  width: 98%;
  padding-bottom: 8px;
  /* Padding at the bottom */
  margin-bottom: 12px;
  /* Margin at the bottom */
  border-bottom: 1px solid black;
  /* Border at the bottom */
}




.navbar-pro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 3rem;
}



@media (max-width: 600px) {

  .navbar-pro {

    justify-content: center;
    align-items: center;
    display: flex;
  }


}

@media (max-width: 500px) {

  .filter_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

  }

  .filter_container {
    display: flex;
    flex-direction: column;
  }

  .Main_filter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .attribute-label {

    width: 4rem;
  }

  .detail-pro {
    width: 100% !important;
  }

}

.navbar-item-pro {
  color: black;
  padding: 8px 15px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  font-size: 20px;
  cursor: pointer;
}

.navbar-item-pro.active {
  border-bottom: 3px solid black;
}

.table-pro {
  justify-content: flex-start;
  width: 95%;
}


.detail-pro {
  display: flex;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #101010;
  padding-bottom: 1rem;
  text-align: start;
  margin-left: 1.5rem;
  width: 40%;
}


.inside-tab {

  background-color: #EFEFEF;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  border-radius: 10px;
}

.carousel-content {
  width: 100%;
}