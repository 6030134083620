.gegga {
    width: 0;
}

.Main_loader {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.snurra {
    filter: black;
}

.stopp1 {
    stop-color: #f700a8;
}

.stopp2 {
    stop-color: #ff8000;
}

.halvan {
    animation: Snurra1 8s infinite linear;
    stroke-dasharray: 180 800;
    fill: none;
    stroke: black;
    stroke-width: 23;
    stroke-linecap: round;
}

.strecken {
    animation: Snurra1 3s infinite linear;
    stroke-dasharray: 26 54;
    fill: none;
    stroke: black;
    stroke-width: 23;
    stroke-linecap: round;
}

.skugga {
    filter: blur(5px);
    opacity: 0.3;
    position: absolute;
    transform: translate(3px, 3px);
}

@keyframes Snurra1 {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: -403px;
    }
}