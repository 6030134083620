.card_image_div {
  width: 20rem;
}

.Main_card_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 1rem;
  cursor: pointer;
  width: 20rem;
}

.card_text_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}

.card_image {
  width: 230px;
  height: 230px;
  object-fit: cover;
}

.card_image:hover {
  transform: scale(1.06);
  transition: all 0.5s ease-in-out;
}

.card_text1 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 500;
  height: 2.5rem;
}

.card_text2 {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: initial;
  font-size: 0.9rem;
  height: 5rem;

}

.card_text3 {
  font-size: 1rem;

  font-weight: 500;
}

.out-of-stock-message {
  color: red;
  padding: 5px;
  font-size: small;
  height: 5rem;
}

@media (max-width: 500px) {

  .card_text_div {

    width: 15rem;
  }

  .card_image_div {
    width: 5rem;
  }

}

.see-more-link {
  color: #4E4E4F;
  text-decoration: none;
  /* Remove the underline */
}

.see-more-link:hover {
  text-decoration: underline;
  /* Add underline on hover */
}

@media screen and (max-width: 768px) {
  .card_text_div {
    gap: 1.3rem;
  }

}