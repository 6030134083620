.Main_Products {
    width: 85%;
    margin: auto;
    margin-top: 2rem;
}

.Products_header {
    display: flex;

}

.section_route {
    opacity: 0.7;
}

.section_name {
    opacity: 1;
    font-weight: 500;
    margin-left: 4px;
}

.Products_container_filters {
    margin-top: 4rem;
}

.filter_container2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Products_container_products {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.Products_container {
    width: 95%;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Products_container_pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.pagination_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}

.pagination_container_item {
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.5;
}

.pagination_container_item:hover {
    opacity: 1;
}

.pagination_container_item_active {
    font-size: 1.2rem;
    font-weight: 500;

    opacity: 1;
}

.filter_conts {
    width: 50%;
}

.filter_container_text {
    display: flex;
    margin-top: 4rem;
    font-size: 2.5rem;
    font-weight: 500;
}

.Section2_underline {
    width: 100px;
    height: 2px;
    background-color: #000000;

    margin-bottom: 1rem;
    margin-top: 2rem;
    display: flex;
}