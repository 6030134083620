.Main_Home {
  margin-top: 0rem;
}

.home_vid {
  width: 100%;
  height: 900px;
  object-fit: cover;
  position: relative;
  /* z-index: 2; */
}

.Home_vid_text {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.Section1 {
  margin-top: -5rem;
  position: relative;
  z-index: 2;
  width: fit-content;
}

.Section3 {
  position: relative;
  z-index: 2;
  width: fit-content;
}

.Home_Watch_Section {
  width: 30%;
  margin: auto;
}

.Home_Watch_Section2 {
  width: fit-content;
  margin: auto;
  margin-left: 4px;
}

.Home_Watch_Section3 {
  width: fit-content;
  margin: auto;
}

.Home_Watch_Section_container_main {
  background-color: #f4f4f4;
  height: 600px;
}

.Home_Watch_Section_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: auto;
  gap: 1rem;
  width: 80%;
}

.Home_Watch_Section_container2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: auto;
  gap: 1rem;
  width: 65%;
  margin-top: 1rem;
}

.Home_image_section3 {
  width: 400px;
  /* height: 370px; */
}

.Home_text_section1 {
  position: absolute;
  top: 0%;
  /* left: 4%; */
  width: 100%;
  height: 99%;
  color: white;
  background: linear-gradient(40deg,
      rgba(0, 0, 0, 0.7) 21.21%,
      rgba(0, 0, 0, 0) 81.85%);
}

.Home_section1_text {
  font-size: 2rem;
  font-family: sans-serif;
  font-weight: bold;
  margin-bottom: 1rem;
  width: 70%;
  text-align: initial;
  position: absolute;
  top: 40%;
  left: 10%;
}

.Home_section1_subtext {
  font-size: 15px;
  font-family: sans-serif;
  position: absolute;
  top: 30%;
  right: 71%;
  width: fit-content;
}

.Home_section2_subtext {
  font-size: 15px;
  font-family: sans-serif;
  position: absolute;
  top: 30%;
  right: 61%;
  width: fit-content;
}

.Home_image_section1 {
  width: 600px;
}

.Home_image_section2 {
  width: 400px;
}

.Home_image {
  width: 100%;
  height: 300px;
}

.Home_vid_text1 {
  font-size: 1.6rem;
  font-family: sans-serif;
  /* font-weight: bold; */
  margin-bottom: 1rem;
}

.Home_vid_text2 {
  font-size: 2rem;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 400px;
}

.button_img {
  position: absolute;
  top: 75%;
  left: 10%;
}

.Home_vid_button,
.Home_vid_button-theird-section {
  background-color: transparent;
  border: 2px solid white;
  cursor: pointer;
  padding: 10px;
  color: white;
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: bold;
  width: 200px;
  letter-spacing: 2px;
}

.Home_vid_button_section {
  margin-top: 1rem;
}

.Home_Section3 {
  width: 100%;
  height: auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.Home_Section3_img {
  width: 500px;
  height: 400px;
}

.Home_Section3_img img {
  height: 100%;
}


.Home_Section3_text {
  width: 55%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}

.Home_Section3_text1 {
  font-size: 16px;
  color: white;
  letter-spacing: 2px;
  font-weight: 500;
}

.Home_Section3_text2 {
  font-size: 2rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 400px;
  display: flex;
  align-items: center;
  text-align: initial;
}

.Home_Section3_text3 {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: initial;
  opacity: 0.7;
}

.text_cont1 {
  width: 50%;
  display: flex;
}

.text_cont2 {
  width: 50%;
  display: flex;
}

.text_cont3 {
  width: 60%;
  display: flex;
  margin-left: 5rem;
}

.section3_img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.Home_Section4_cards1 {
  width: fit-content;
  display: flex;
  flex-direction: row;
}

.Home_Section3_button_section {
  width: 50%;
  display: flex;
  margin-top: 2rem;
}

.carousel-container1 {
  height: 10rem;
}

.Home_Section4 {
  margin-top: 6rem;
}


.Home_Section4_cards {
  width: 100%;

  margin-top: 4rem;
  display: flex;
  flex-direction: row;

  gap: 2rem;
  justify-content: center;
}

.postWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

}

@media screen and (max-width:768px) {

  .postWrapper {
    flex-wrap: wrap;

  }

  .Home_vid_button-theird-section {
    width: 180px;
    font-size: 0.9rem;
  }

}

.carousel-item {
  margin-right: 20rem;
  /* Adjust the value as needed for your desired gap */
}

.Home_Section5 {
  margin-top: 5rem;
}

.Home_Section5_cards {
  width: 85%;
  margin: auto;
  margin-top: 4rem;
}

.Home_Section5_cards_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.Home_Section5_img {
  width: 200px;
  height: 50px;
  object-fit: contain;
}

.Home_Section5_img_cont {
  width: 220px;
}

.Home_Section6 {
  margin-top: 5rem;
}

.Section6_imgs_cont {
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Home_Section6_img {
  width: 253px;
  height: 240px;
  object-fit: cover;
}

.insta_cont {
  width: 253px;
  height: 240px;
  background-color: #141420;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

@media screen and (max-width: 510px) {
  .Home_Section6_img {
    width: 80%;

  }

  .insta_cont {
    width: 80%;
  }

}

.insta_text {
  font-size: 1.3rem;
  margin-top: 4px;
}

@media Screen and (max-width: 1100px) {
  .Main_Home {
    margin-top: -1rem;
  }

  .text_cont3 {
    margin-left: 4rem;
  }
}

@media Screen and (max-width: 960px) {
  .text_cont1 {
    width: 100%;
  }

  .text_cont2 {
    width: 100%;
  }

  .Home_Section3_text1 {
    margin: auto;
  }

  .Home_Section3_text2 {
    text-align: left;
    width: 100%;
  }

  .text_cont3 {
    width: 100%;
  }

  .Home_Section3_text3 {
    text-align: center;
  }



  .Home_Section3_button_section {
    width: 100%;
    justify-content: left;
  }
}

@media Screen and (max-width: 700px) {
  .Home_Section3_text {
    position: absolute;
    margin: 0;
    padding: 1rem 1rem 2rem 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 9;
  }

  .Home_vid_text {
    top: 80% !important;
  }

  .Home_Section3 {
    background-color: white !important;
    text-align: left;
  }

  .Home_Section3_text1 {
    width: 100%;
    color: black;
  }

  .Home_Section3_text2 {
    width: 100%;
    color: black;
    text-shadow: 2px 2px 2px black;

  }

  .Home_Section3_text3 {
    width: 100%;
    word-spacing: 3px;
    font-size: 1rem;
    color: black;
    text-shadow: 1px 0px 2px black;
    text-align: center !important;
  }

  .text_cont3 {
    margin-left: 1rem;

  }

  .Home_vid_text {
    top: 85%;
  }

  .Home_vid_button-theird-section {
    color: rgb(255, 255, 255);
    border: none;
    background-color: black;
  }

  .Home_Section3_img {
    width: 100%;
  }

  .Home_Section3_img img {
    opacity: 0.6;
  }

}

@media Screen and (max-width: 400px) {
  .Home_vid_text2 {
    width: 250px;
  }
}

@media Screen and (max-width: 350px) {
  .Home_vid_text {
    top: 95%;
  }
}

/*@media Screen and (max-width: 290px) {
    .Main_Home {
        margin-top: -18rem;
    }
} */



/* White Gradient mixin */
.white-gradient {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%);
}

/* Animation speed variable */
:root {
  --animation-speed: 40s;
}



/* Slider styles */
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  height: 130px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* Slider gradient pseudo-elements */
.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

}


.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}



/* Individual slide styles */


@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 14 + 250px));
    /* Adjust the total number of slides */
  }
}

/* Adjust the animation duration for the specific use case */
.slide-track {
  animation: scroll var(--animation-speed) linear infinite;
  display: flex;
  width: calc(250px * 14);
  /* Adjust the total width of the slide track */
  overflow: hidden;
  /* Hide the overflow to create a scrolling effect */
  justify-content: space-between;
  /* Space the slides evenly */
}

/* Individual slide styles */
.slide {
  flex: 0 0 auto;
  /* Ensure each slide has a fixed size */
  width: 250px;
  /* Adjust the width of each slide based on your design */
}