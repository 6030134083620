.top_img {
    margin-top: 50%;
    width: 80%;
    /* height: 500px; */
    object-fit: cover;
}

.Header {
    padding-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative;
}

.Header img {
    max-width: 500px;
    max-height: 120px;
}

.Header .swatch_details {
    background-color: black;
    color: white;
    width: 70%;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    line-height: 2rem;
    opacity: 1;
}

.slider-container {
    width: 100%;
    height: 200px;
}

.big-image-container {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;

}

.CarouselContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.CarouselCenter {
    width: 50%;
}

.CarouselCenter img {
    width: 100%;
    height: 100%;
}

.fill {
    width: 100%;
    height: 100%;
}

.about-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.about-section .center-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Updated to flex-start */
    justify-content: flex-start;
    /* Updated to flex-start */
    width: 90%;
    max-width: 1000px;
    /* Added max-width for better responsiveness */
    margin: 0 auto;
    /* Center the container horizontally */
    margin-bottom: 1rem;
}

.about-section .center-container h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    /* Added margin for better spacing */
}

.about-section .center-container p {
    text-align: left;
    line-height: 1.6;
    margin-left: 2rem;
    /* Improved line height for better readability */
}


.slider-container {
    width: 100%;
    padding: 4rem 0;
}

.slider-content img {
    width: 100%;
}

.descrition-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.descrition-center {
    max-width: 1000px;
    padding: 0 2rem;
    width: 100%;
}

.descrition-center p {
    text-align: left;
    width: 100%;
}

.images-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.images-section img {
    width: 500px;
    height: 300px;
    border-radius: 0.8rem;
}

@media screen and (max-width: 768px) {

    .about-section .center-container h3 {
        font-size: 1.5rem;
    }

    .about-section .center-container p {
        font-size: 0.8rem;
        margin: 0;
    }

    .images-section {
        flex-wrap: wrap;
    }

    .images-section img {
        width: 90%;
        object-fit: fill;
        width: 250px;
        height: 150px;
    }

    .slider-container {
        padding: 0.4rem 0;
    }
}