.vacancy_details_main_div {
  width: 90%;
}

.vacancy_details_title {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
}

.vacancy_details_form_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
}

.vacancy_details_form_row_col {
  width: 46%;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.vacancy_details_form_row_col1 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.vacancy_details_form_labels {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 15px;
}

.vacancy_details_input,
.vacancy_details_input1 {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  width: 95%;
  height: 50px;
  padding-left: 15px;
  border: #141420 solid 1px;
  background-color: #f2f2f2;
}

.vacancy_details_input1 {
  height: 100px !important;
}

.vacancy_details_form_row_submit_btn {
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.apply_btn {
  width: 150px !important;
}

@media Screen and (max-width: 660px) {
  .vacancy_details_form_row {
    flex-direction: column;
  }

  .vacancy_details_form_row_col {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }
}