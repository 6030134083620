.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
  font-family: "sans-serif";
  src: url("./assets/Fonts/Montserrat-Medium.ttf");
}

:root {
  --black: #000000;
  --lightblack: #141420;
  --regular: "regular";
}

.font-regular {
  font-family: sans-serif !important;
}