.more-filters-section {
  transition: all 2s ease-in-out;
  height: 95vh;
  width: 20%;
  background: #f8f8f8;
  position: fixed;
  z-index: 22;
  top: 0;
  left: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
  overflow: auto;
  gap: 2rem;
}
.shadow{
  width: 80%;
  right: 0;
  top: 0;
  height: 100%;
  background-color: black;
  position: fixed;
  opacity: 0.6;
  transition: all .3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .more-filters-section {
    width: 40%;
  }
  .shadow{
    width: 60%;
  }
}

.filter_header_close {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.testestofinput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.filter_header_search {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  background: #fbfbfb;
  gap: 4px;
  width: 90%;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 1rem;
  border-radius: 0.4rem;
}

.filter_header_search input {
  outline: none;
}

.filter_search_input {
  border: none;
  padding: 8px 4px;
}

.filter_search_icon {
  opacity: 0.5;
}

.filter_header_title {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.filter_header_filters_cont {
  cursor: pointer;
}

.filter_header_filters {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: initial;
}

.filter_header_filters11111 {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: initial;
  gap: 1rem;
  margin-left: 1rem;
}

.filters_cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filters_cont {
  text-align: initial;
  margin-top: 10px;
}

.label_check1 {
  font-size: 1.1rem;
  font-weight: 500;
}

.checkboxss:checked+.label_check1 {
  color: #ff5a5f !important;
}

.sub-category-arrow {
  margin-left: 10px;
}

.clear_all_filters{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.clear_all_btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 0.5rem 0.5rem;
  border: none;
  background-color: #ffffff;
  color: #000000; 
  border: 1px solid black;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
  transform: translateY(-1rem);

}
.clear_icon{

}


@media screen and (max-width: 900px) {
  .clear_all_btn{
    width: 70%;
    font-size: 0.8rem;
  }
  .clear_icon{
    width: 20px;
    height: 20px;
  }
}