.chekout_header {
  font-weight: 400;
  font-size: 44px;
  line-height: 54px;
  text-transform: uppercase;
  color: #000000;
  width: 35%;
  text-align: initial;
}

.chekout_header2 {
  margin-top: 2rem;

  line-height: 14px;
  color: #000000;
  display: flex;
  font-family: sans-serif;
  font-style: normal;

  font-size: 18.32px;
  margin-bottom: 2rem;
  font-weight: 1000 !important;

  text-transform: uppercase;
}

.order_details1 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  gap: 10rem;
  margin-top: 2rem;
  text-align: initial;
  margin-bottom: 2rem;
}

.order_details1_text {
  margin-top: 7px;

  line-height: 14px;
  color: #000000;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17.32px;

  text-transform: uppercase;
}

.order_details1_text1 {
  margin-top: 1rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  /* identical to box height, or 179% */

  color: #333333;
}

.quantity_div1 {
  width: 30px;
  font-weight: 500;
  text-align: center;
  color: #141420;
  /* border: #D9D9D9 solid 1px; */
  font-size: 14px;
}

.address_cont1_text {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 17px;
  line-height: 14px;
  color: #000000;
  display: flex;
}

.address_cont1_text1 {
  display: flex;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  width: 20%;
  text-align: initial;
}

.address_cont2 {
  margin-bottom: 4rem;
}

.my_cart {
  height: 16rem;

  margin-right: 9rem;
  margin-bottom: 1rem;
  width: 100%;
  background: #222235;
}

.inside {
  height: 16rem;

  margin-right: 9rem;
  margin-bottom: 1rem;
  max-width: 100rem;
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  justify-content: flex-start;
}

.cart_product_image {
  width: 200px;
  height: 200px;
}

.cart_product_image img {
  width: 100%;
  height: auto;
}

.homecontainer {
  color: #f7f7f7;
  flex-direction: column;
  display: flex;
  column-gap: 3rem;
}

.home {
  padding-top: 4rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff53;

  margin-left: 5.3rem;
  margin-bottom: 0.5rem;
}

.myCart {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 54px;
  /* identical to box height */

  text-transform: uppercase;
  margin-left: 3rem;
  color: #ffffff;
  padding-bottom: 4rem;
}

.cartname {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  text-transform: uppercase;

  color: #ffffff;
}

.firstcontainer {
  display: flex;
  flex-direction: column;
}